var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-table",
            {
              attrs: {
                title: "즉시조치",
                columns: _vm.grid.columns,
                gridHeight: _vm.grid.height,
                data: _vm.grid.data,
                filtering: false,
                columnSetting: false,
                usePaging: false,
                editable: _vm.editable,
              },
              on: { linkClick: _vm.immlinkClick },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: {
                              label: "LBLADD",
                              showLoading: false,
                              icon: "add",
                            },
                            on: { btnClicked: _vm.addImprImm },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-table",
            {
              staticClass: "q-mt-md",
              attrs: {
                title: "LBLIMPRREQUEST",
                columns: _vm.grid2.columns,
                gridHeight: _vm.grid2.height,
                data: _vm.grid2.data,
                filtering: false,
                columnSetting: false,
                usePaging: false,
                editable: _vm.editable,
              },
              on: { linkClick: _vm.imprlinkClick },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: {
                              label: "LBLADD",
                              showLoading: false,
                              icon: "add",
                            },
                            on: { btnClicked: _vm.addImpr },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }